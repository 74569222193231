<template>
  <article>
    <RouterNav :name="className"></RouterNav>
    <header class="header">
      <div class="label">当前查看：</div>
      <div class="tag">{{className}}</div>
      <div class="tag">{{type === 1 ? '听说模考' : '笔试模考'}}</div>
    </header>

    <div>
      <ExamCharts
        :className="className"
        :classId="classId"
        :type="type"
        ref="examCharts"
      ></ExamCharts>

      <section class="section">
        <div class="title">
          <div class="left">
            <div class="line"></div>
            <div>学生报告</div>
          </div>
          <div class="right" @click="studentReportChange">
            <div class="hidden">{{studentReportShow ? '点击收起' : '点击查看更多'}}</div>
            <div :class="['btn', studentReportShow ? 'rotate-180' : '']"></div>
          </div>
        </div>
        <div v-show="studentReportShow">
          <div class="sub">
            <el-tooltip class="item" effect="dark" content="优异学生：得分率≥80%的学生" placement="top-start">
              <div class="sub-title">
                <span>表现优异学生</span><img class="mark" src="../../../assets/mark-2.png" alt="?">
              </div>
            </el-tooltip>
          </div>
          <div class="table">
            <el-table
              :data="excellentListShowAll ? excellentList : excellentList.slice(0, 5)">
              <el-table-column
                prop="rank"
                label="排名"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="name"
                label="姓名"
                align="center"
              ></el-table-column>
              <el-table-column
                label="得分率"
                sortable
                sort-by="scoreRate"
                align="center"
              >
                <template slot-scope="props">
                  <div :class="props.row.scoreRate > 85 ? 'col-1DC060' : props.row.scoreRate > 60 ? 'col-fed942' : 'col-FF3C30'">{{props.row.scoreRate}}%</div>
                </template>
              </el-table-column>
              <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                  <div class="link" @click="userReport(scope.row.studentId)">查看个人报告</div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="more-btn" @click="listShowAllChange('excellentListShowAll')" v-if="excellentList.length > 5">
            <div>{{excellentListShowAll ? '收起' : '查看更多'}}</div>
            <img :class="[excellentListShowAll ? 'rotate-180' : '']" src="../../../assets/z-1.png" alt="more">
          </div>

          <div class="sub sub-3">
            <el-tooltip class="item" effect="dark" content="需重点关注学生：得分率<60%的学生" placement="top-start">
              <div class="sub-title">
                <span>重点关注学生</span><img class="mark" src="../../../assets/mark-2.png" alt="?">
              </div>
            </el-tooltip>
          </div>
          <div class="table">
            <el-table
              :data="monitorListShowAll ? monitorList : monitorList.slice(0, 5)">
              <el-table-column
                prop="rank"
                label="排名"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="name"
                label="姓名"
                align="center"
              ></el-table-column>
              <el-table-column
                label="得分率"
                sortable
                sort-by="scoreRate"
                align="center"
              >
                <template slot-scope="props">
                  <div :class="props.row.scoreRate > 85 ? 'col-1DC060' : props.row.scoreRate > 60 ? 'col-fed942' : 'col-FF3C30'">{{props.row.scoreRate}}%</div>
                </template>
              </el-table-column>
              <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                  <div class="link" @click="userReport(scope.row.studentId)">查看个人报告</div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="more-btn" @click="listShowAllChange('monitorListShowAll')" v-if="monitorList.length > 5">
            <div>查看更多</div>
            <img :class="[monitorListShowAll ? 'rotate-180' : '']" src="../../../assets/z-1.png" alt="more">
          </div>

          <div class="sub sub-2">
            <el-tooltip class="item" effect="dark" content="默认根据“得分率”的值由高到低排名" placement="top-start">
              <div class="sub-title">
                <span>全部学生列表</span><img class="mark" src="../../../assets/mark-2.png" alt="?">
              </div>
            </el-tooltip>
          </div>
          <div class="table">
            <el-table
              :data="studentListShowAll ? studentList : studentList.slice(0, 10)">
              <el-table-column
                prop="rank"
                label="排名"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="name"
                label="姓名"
                align="center"
              ></el-table-column>
              <el-table-column
                label="得分率"
                sortable
                sort-by="scoreRate"
                align="center"
              >
                <template slot-scope="props">
                  <div :class="props.row.scoreRate > 85 ? 'col-1DC060' : props.row.scoreRate > 60 ? 'col-fed942' : 'col-FF3C30'">{{props.row.scoreRate}}%</div>
                </template>
              </el-table-column>
              <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                  <div class="link" @click="userReport(scope.row.studentId)">查看个人报告</div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="more-btn" @click="listShowAllChange('studentListShowAll')" v-if="studentList.length > 10">
            <div>{{studentListShowAll ? '收起' : '查看更多'}}</div>
            <img :class="[studentListShowAll ? 'rotate-180' : '']" src="../../../assets/z-1.png" alt="more">
          </div>
        </div>
      </section>

    </div>

  </article>
</template>

<script>
import RouterNav from '@/components/RouterNav'
import ExamCharts from '../components/examCharts'
export default {
  name: 'classExamReport',
  components: {
    RouterNav,
    ExamCharts
  },
  data () {
    return {
      classId: '',
      className: '',
      type: '',
      questionType: 3,
      userList: [],
      studentReportShow: false,
      excellentList: [],
      excellentListShowAll: false,
      monitorList: [],
      monitorListShowAll: false,
      studentList: [],
      studentListShowAll: false
    }
  },
  mounted () {
    this.$store.commit('includeAdd', 'classExamReport')
    const { query } = this.$route
    this.classId = query.id
    this.className = query.name
    this.type = query.type
  },
  methods: {
    studentReportChange () {
      this.studentReportShow = !this.studentReportShow
      if (this.studentReportShow && this.studentList.length === 0) {
        this.getStudentReportExam()
      }
    },
    getStudentReportExam () {
      this.$axios({
        method: 'post',
        url: this.$urls.getStudentReportExam,
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          classId: this.classId,
          type: this.type
        }
      })
        .then((response) => {
          if (response.state === '11') {
            const { data } = response
            this.excellentList = data.excellentList
            this.monitorList = data.monitorList
            this.studentList = data.studentList
          }
        })
    },
    listShowAllChange (attr) {
      this[attr] = !this[attr]
    },
    userReport (id) {
      this.$router.push({
        name: 'userExamReport',
        query: {
          id,
          type: this.type,
          classId: this.classId
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .header {
    display: flex;
    align-items: center;
    background-color: #fff;
    height: 60px;
    padding: 0 28px;
    margin-bottom: 10px;
    font-size: 14px;
    .label {
      margin-right: 14px;
    }
    .tag {
      background-color: #EAF4FD;
      height: 26px;
      line-height: 26px;
      padding: 0 14px;
      border-radius: 13px;
      margin-right: 10px;
      color: #309AF2;
      text-align: center;
      cursor: pointer;
    }
  }
  .section {
    background-color: #fff;
    margin-bottom: 10px;
    overflow: hidden;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 66px;
      padding-right: 28px;
      @include font;
      font-weight: bold;
      &:hover {
        .hidden {
          visibility: visible;
        }
      }
      .left {
        display: flex;
        align-items: center;
      }
      .line {
        background-color: #309AF2;
        width: 4px;
        height: 14px;
        margin-right: 24px;
      }
      .icon {
        background-image: url("../../../assets/icon-2.png");
        background-size: 100% 100%;
        width: 10px;
        height: 10px;
        margin-left: 20px;
      }
      .tips {
        background-color: #EAF4FD;
        height: 34px;
        line-height: 34px;
        padding: 0 20px;
        border-radius: 4px;
        font-size: 16px;
        font-weight: normal;
      }
      .right {
        display: flex;
        align-items: center;
        color: #309AF2;
        font-size: 14px;
      }
      .btn {
        background: #EAF4FD url("../../../assets/z-1.png") no-repeat center center;
        background-size: 11px 6px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-left: 10px;
        cursor: pointer;
        transition: all .3s;
      }
      .loading-btn {
        background: #EAF4FD url("../../../assets/loading.png") no-repeat center center;
        background-size: 16px 16px;
        animation: rotate .5s linear infinite;
      }
    }
    .charts {
      margin: 0 28px 20px;
      border: 1px solid #E5E5E5;
      .radio {
        padding: 12px 14px 0;
      }
    }
    .wrapper {
      overflow: auto;
      margin: 0 28px 28px;
      .chartsList {
        height: 280px;
        border: 1px solid #E5E5E5;
      }
    }
    .sub {
      border-top: 2px solid #1DC060;
      .sub-title {
        display: inline-block;
        background: linear-gradient(to right, #1DC060, #3EE199);
        height: 28px;
        line-height: 28px;
        margin-left: 28px;
        padding: 0 14px;
        border-radius: 0 0 8px 8px;
        color: #fff;
        font-size: 14px;
        .mark {
          width: 14px;
          height: 14px;
          margin-left: 6px;
          vertical-align: middle;
        }
      }
      .sub-title-default {
        background: linear-gradient(to right, #309AF2, #5ECAFA);
      }
      .sub-title-warn {
        background: linear-gradient(to right, #FF6F5E, #FF6F5E);
      }
    }
    .sub-2 {
      border-top: 2px solid #309AF2;
      .sub-title {
        background: linear-gradient(to right, #309AF2, #5ECAFA);
      }
    }
    .sub-3 {
      border-top: 2px solid #FF3C30;
      .sub-title {
        background: linear-gradient(to right, #FF3C30, #FF6F5E);
      }
    }
    .table {
      padding: 0 28px 20px;
      .link {
        color: #309AF2;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .more-btn {
      @include flex;
      background-color: #F6F6F6;
      width: 120px;
      height: 32px;
      margin: 0 auto 40px;
      border-radius: 20px;
      color: #309AF2;
      font-size: 14px;
      cursor: pointer;
      img {
        width: 7px;
        height: 4px;
        margin-left: 4px;
        transition: all .3s;
      }
    }
  }
</style>
